import React from "react";
import "./askedQuestions.css";
const AskedQuestions = () => {
  const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="aq_box">
        <div className="aq_box_item" onClick={() => setOpen(!isOpen)}>
          <p>{title}</p>
        </div>
        <div className={`aq_box_item_content ${!isOpen ? "active" : ""}`}>
          <div className="aq_content">{children}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="aq">
      <h1 className="aq_title">Frequently Asked Questions</h1>

      <div className="aq_box">
        <Accordion title="What is a installment loan?">
        A personal loan is a way for an individual to receive up to $35,000 in funding. It is a loan that can be used for home enhancements, luxury item 
        purchases, vehicle repairs or large purchases. The length of the loan is called the term and can range all the way up to 180 days, depending on the 
        lender. Our simple online form does not contain any long questions. Instead, it asks for your basic information and a location for where you would like 
        the loan to be sent to. It is that easy!
        </Accordion>
        <Accordion title="What are the basic loan requirements?">
          Must be at least 18 years old.
          Must be a U.S. citizen or Permanent Resident.
          Currently employed or receive steady income.
          Have a bank account to receive the funds, ideally with direct deposit.
        </Accordion>
        <Accordion title="Are there any fees?">
          A black hole is an area of such immense gravity that nothing -- not
          even light -- can escape from it.
        </Accordion>
      </div>
    </div>
  );
};

export default AskedQuestions;
